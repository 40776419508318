export default {
    aPostBodyInit: [],
    oDev: {
        bDevMode: true,
        oUrlOptsInit: { limit: 10 },
        sBaseUrl: 'https://cap.orenkontur.ru:49253/apitest/v3/true-api',
        sBaseUrlV4: 'https://cap.orenkontur.ru:49253/apitest/v4/true-api',
        // sBaseUrl: 'http://192.168.7.125:49253/apitest/v3/true-api',
        sOmsBaseUrl: 'https://cap.orenkontur.ru:49253/suzapitest/v3',
        sOmsConnUuid: '9f5f7f23-ff05-4114-9a02-907d1bd9e546',
        sOmsUuid: '8ceca032-bae1-4167-a30b-7e9598c45ea8',
        sDbBaseUrl: 'http://192.168.7.234:5984/ngwater/',
    },
    oProd: {
        oUrlOptsInit: { limit: 1000 },
        sBaseUrl: 'https://cap.orenkontur.ru:49253/api/v3/true-api',
        sBaseUrlV4: 'https://cap.orenkontur.ru:49253/api/v4/true-api',
        sOmsBaseUrl: 'https://cap.orenkontur.ru:49253/suzapi/v3',
        sOmsConnUuid: '10de5774-c878-4b32-9bb5-660f967047d0',
        sOmsUuid: '2e685f96-6c7a-418e-bc1f-943564edac6d',
        sDbBaseUrl: 'https://cdb.orenkontur.ru/ngwater/',
}
};
